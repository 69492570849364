const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
};

export default styles;
