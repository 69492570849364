import { api } from 'services';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import constants from 'constants/index';

const initialState = {
  data: null,
  loading: false,
  error: null
};

const getBookingStatusData = ({ booking_status }) => booking_status.data;
const getBookingStatusError = ({ booking_status }) => booking_status.error;
const getBookingStatusLoading = ({ booking_status }) => booking_status.loading;

const selectBookingStatusData = createSelector(getBookingStatusData, data => data);
const selectBookingStatusError = createSelector(getBookingStatusError, error => error);
const selectBookingStatusLoading = createSelector(getBookingStatusLoading, loading => loading);

const fetchBookingStatus = createAsyncThunk(
  'booking/fetchBookingStatus',
  async (acc_id, thunkAPI) => {
    const data = await api.post(
      `/bookings/track_status`,
      { acc_id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(constants.AUTH_TOKEN_KEY)}`
        }
      }
    );
    return data;
  }
);

const bookingStatus = createSlice({
  name: 'booking_status',
  initialState,
  reducers: {
    // no static reducers
  },
  extraReducers: {
    [fetchBookingStatus.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchBookingStatus.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchBookingStatus.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
});

export const actions = {
  fetchBookingStatus
};

export const selectors = {
  selectBookingStatusData,
  selectBookingStatusError,
  selectBookingStatusLoading
};

export default bookingStatus.reducer;