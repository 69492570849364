import { forwardRef } from 'react';
import PropTypes from 'prop-types';

// components
import { Box } from '@material-ui/core';

const Div = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} component="div" {...props}>
      {children}
    </Box>
  );
});

export default Div;

Div.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
