const theme = {
  palette: {
    primary: {
      main: '#C8250A',
    },
    common: {
      white: '#ffffff',
      black: '#000000'  
    },
    secondary: {
      main: '#b24a89',
    },
    background: {
      default: '#eee',
    },
    border: {
      main: '#dce0e0'
    },
    text: {
      primary: '#222',
      secondary: '#9a9b9f'
    },
    success: {
      main: '#259b24'
    }
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        position: 'fixed',
        width: '100%',
        height: 2,
        top: 43
      }
    }
  }
};

export default theme;