import { api } from 'services';
import constants from 'constants/index';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: null,

  creatingBooking: false,
  createBookingError: null,
  bookingToken: '',
  bookingUrl: '',

  dates: [],
  partner: null,
  fechingDates: false,
  fetchDatesError: null,
};

// data selectors
const getAvailableSlots = ({ booking }) => booking.data?.available_slots || [];
const getQueueEnabled = ({ booking }) => booking.queueEnabled;
const getTimeSlotsError = ({ booking }) => booking.error;
const getTimeSlotsLoading = ({ booking }) => booking.loading;
const getCreatingBooking = ({ booking }) => booking.creatingBooking;
const getBookingToken = ({ booking }) => booking.bookingToken;
const getBookingUrl = ({ booking }) => booking.bookingUrl;
const getCreateBookingError = ({ booking }) => booking.createBookingError;
const getDates = ({ booking }) => booking.dates;
const getFetchingDates = ({ booking }) => booking.fechingDates;
const getFetchDatesError = ({ booking }) => booking.fetchDatesError;
const getPartner = ({ booking }) => booking.partner;
const getPeopleInQueue = ({ booking }) => booking.peopleInQueue;
const getNextAvailableSlot = ({ booking }) => booking.nextAvailableSlot;
const getRefreshQstatus = ({ booking }) => booking.refreshQstatus;

const selectAvailableSlots = createSelector(getAvailableSlots, availableSlots => availableSlots);
const selectQueueEnabled = createSelector(getQueueEnabled, queueEnabled => queueEnabled);
const selectPeopleInQueue = createSelector(getPeopleInQueue, peopleInQueue => peopleInQueue);
const selectNextAvailableSlot = createSelector(getNextAvailableSlot, nextAvailableSlot => nextAvailableSlot);
const selectTimeSlotsError = createSelector(getTimeSlotsError, error => error);
const selectTimeSlotsLoading = createSelector(getTimeSlotsLoading, loading => loading);
const selectCreatingBooking = createSelector(getCreatingBooking, creatingBooking => creatingBooking);
const selectBookingToken = createSelector(getBookingToken, bookingToken => bookingToken);
const selectBookingUrl = createSelector(getBookingUrl, bookingUrl => bookingUrl);
const selectCreateBookingError = createSelector(getCreateBookingError, createBookingError => createBookingError);
const selectGetDates = createSelector(getDates, dates => dates);
const selectGetFetchingDates = createSelector(getFetchingDates, fechingDates => fechingDates);
const selectGetFetchDatesError = createSelector(getFetchDatesError, fetchDatesError => fetchDatesError);
const selectGetPartner = createSelector(getPartner, partner => partner);
const selectRefreshQstatus = createSelector(getRefreshQstatus, refreshQstatus => refreshQstatus);

const fetchDates =  createAsyncThunk(
  'booking/dates',
  async (queueId, thunkAPI) => {
    const data = await api.get(`/mqueues/${queueId}`);
    return data;
  }
);

const fetchTimeSlots = createAsyncThunk(
  'booking/timeSlots',
  async ({ queueId, date }, thunkAPI) => {
    const data = await api.get(`/mqueues/${queueId}/timeslots?selected_date=${date}`);
    return data;
  }
);

const createBooking = createAsyncThunk(
  'booking/create',
  async ({ queue_id, appointment_time }, thunkAPI) => {
    const data = await api.post(
      `/bookings`,
      { queue_id, appointment_time },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(constants.AUTH_TOKEN_KEY)}`
        }
      }
    );
    return data;
  }
);

const timeSlots = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetBooking(state, action) {
      state.creatingBooking = false;
      state.createBookingError = null;
      state.bookingToken = '';
      state.bookingUrl = '';
      state.refreshQstatus = true;
    }
  },
  extraReducers: {
    [fetchTimeSlots.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchTimeSlots.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [fetchTimeSlots.rejected]: (state, action) => {
      state.error = action?.error?.message;
      state.loading = false;
    },
    [fetchDates.fulfilled]: (state, action) => {
      const { queue_settings, ...partner } = action.payload;
      state.queueEnabled = queue_settings?.queue_enabled;
      state.peopleInQueue = queue_settings?.people_in_queue;
      state.nextAvailableSlot = queue_settings?.next_available_slot;
      state.dates = queue_settings?.available_dates;
      state.partner = partner;
      state.fechingDates = false;
    },
    [fetchDates.pending]: (state, action) => {
      state.fechingDates = true;
      state.fetchDatesError = false;
    },
    [fetchDates.rejected]: (state, action) => {
      state.fechingDates = false;
      state.fetchDatesError = action?.error?.message;
    },
    [createBooking.fulfilled]: (state, action) => {
      state.bookingUrl = action.payload?.url;
      state.bookingToken = action.payload?.token;
      state.creatingBooking = false;
    },
    [createBooking.pending]: (state, action) => {
      state.creatingBooking = true
      state.error = null
      state.bookingUrl = '';
      state.bookingToken = '';
      state.refreshQstatus = false;
    },
    [createBooking.rejected]: (state, action) => {
      state.error = action.payload;
      state.creatingBooking = false;
      let error_message = action?.error?.message;
      if(error_message == "Request failed with status code 401"){
        state.createBookingError = "Session timed out. Please sign in again";
        localStorage.removeItem(constants.MOBILE_NUMBER_KEY);
        localStorage.removeItem(constants.AUTH_TOKEN_KEY);
        window.location.href = '/';
      }else{
        state.createBookingError = action?.error?.message
      }
    },
  }
});

export const actions = {
  fetchTimeSlots,
  createBooking,
  fetchDates,
  ...timeSlots.actions
};

export const selectors = {
  selectAvailableSlots,
  selectQueueEnabled,
  selectTimeSlotsError,
  selectTimeSlotsLoading,
  selectCreatingBooking,
  selectBookingUrl,
  selectBookingToken,
  selectCreateBookingError,
  selectGetDates,
  selectGetFetchingDates,
  selectGetFetchDatesError,
  selectGetPartner,
  selectPeopleInQueue,
  selectNextAvailableSlot,
  selectRefreshQstatus
};

export default timeSlots.reducer;