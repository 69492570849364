const styles = ({ breakpoints, palette }) => ({
  root: {
    top: '20%',
    outline: 0,
    borderRadius: 10
  },
  heading: {
    height: 35,
    color: palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.primary.main
  },
  paper: {
    position: 'relative',
    maxWidth: 400,
    minHeight: 200,
    margin: '0 auto',
    '&:focus': {
      outline: 0,
    },
  },
  content: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    marginTop: 60,
    textTransform: 'capitalize'
  },
  resendBtn: {
    marginTop: 20,
    textTransform: 'none'
  },
  alignRight: {
    textAlign: 'right'
  }
});

export default styles;