import React from "react";
//Google analytics
import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="User") => {
  const eventTracker = (action = "test", label = "test") => {
    ReactGA.event({category: category, action: action, label: label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;