import { useEffect, useState } from 'react';
import clsx from 'clsx';

// components
import { CircularProgress } from '@material-ui/core';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';

// styles
import styles from './styles';

const PageFallback = () => {
  const classes = useStyles(styles);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHidden(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Div
      className={clsx(classes.container, hidden && classes.hidden)}
    >
      <CircularProgress color='primary' />
    </Div>
  );
};

export default PageFallback;
