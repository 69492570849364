import { Suspense } from 'react';

// components
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header, PageFallback } from 'components';

// constants
import constants from 'constants/index';

// routes
import routesList from 'routes';

const Routes = () => {
  return (
    <Suspense fallback={<PageFallback  />}>
      <Header />
      <Switch>
        {
          routesList.map(
            ({ component, exact, path }) => {
              return (
                <Route key={path} component={component} exact={exact} path={path} />
              );
            }
          )
        }
        <Route path="*">
          <Redirect data-testid="redirect" to={constants.DETAIL_URL} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
