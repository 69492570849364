import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

// TODO: Replace with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKsnODclK1c6cL1T-8VBsM3lrZ9_-IFLQ",
  authDomain: "mobile-token-7503b.firebaseapp.com",
  // The value of `databaseURL` depends on the location of the database
  databaseURL: "https://mobile-token-7503b.firebaseio.com",
  projectId: "mobile-token-7503b",
  storageBucket: "mobile-token-7503b.appspot.com",
  messagingSenderId: "424725178984",
  appId: "1:424725178984:web:42ba3d129e005d9272e9dd",
  // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  measurementId: "G-2QF8ZNKM0K",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);

export default database;