import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState, useRef } from 'react';

// components
import { Button, Modal, Paper, TextField, LinearProgress } from '@material-ui/core';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { actions, selectors } from 'slices';

// styles
import styles from './styles';

const MAX_OTP_WAIT = 30;

const LoginModal = () => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const classes = useStyles(styles);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [fetchingCaptcha, setFetchingCaptcha] = useState(false);
  const [OTP, setOTP] = useState('');
  const [OTPCounter, setOTPCounter] = useState(0);
  const [OTPInterval, setOTPInterval] = useState(null);

  const userId = useSelector(selectors.selectUserId);
  const fetchingOTP = useSelector(selectors.selectFetchingOTP);
  const verifyingOTP = useSelector(selectors.selectVerifyingOTP);
  const sessionToken = useSelector(selectors.selectSessionToken);
  const open = useSelector(selectors.selectShowLoginModal);


  const onChangeName = e => setName(e.target.value);

  const callRecaptcha = () => recaptchaRef.current.execute();

  const fetchOTP = response => {
    dispatch(
      actions.fetchOTP({
        phone_number: number,
        customer_name: name,
        captcha_token: response
      })
    );
    setFetchingCaptcha(false);
    setOTPCounter(0);
    setOTPInterval(
      setInterval(() => {
        setOTPCounter(v => v + 1);
      }, 1000)
    );
    recaptchaRef.current.reset();
  };

  const verifyOTP = () => {
    dispatch(actions.verifyOTP({ user_id: userId, otp_code: OTP }));
  }

  const onChangeOTP = e => setOTP(e.target.value);
  const onChangeNumber = e => setNumber(e.target.value);
  const isValid = /^\d*$/.test(number) && (userId ? OTP.length === 6 : number.length === 10);
  const closeModal = () => dispatch(actions.hideLoginModal());

  useEffect(() => {
    dispatch(actions.resetUserId());
  }, []);

  useEffect(() => {
    if (sessionToken) {
      closeModal();
      window.scrollTo(0, 0);
    }
  }, [sessionToken]);

  useEffect(() => {
    if (OTPCounter === MAX_OTP_WAIT && OTPInterval) {
      clearInterval(OTPInterval);
    }
  }, [OTPCounter, OTPInterval]);

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={closeModal}
      style={{ top: '20%' }}
      aria-labelledby='mobile-login'
      aria-describedby='mobile-login-to-create-booking'
    >
      <Paper className={classes.paper}>
        {fetchingOTP ? <LinearProgress style={{ position: 'absolute', top: 36 }}/> : null}
        <Div className={classes.heading}>
          { userId ? `Verfy OTP` : `Login by mobile number`}
        </Div>
        <Div className={classes.content}>
          { userId ? null : (
            <Div className={classes.content} style={{padding: 0, marginBottom: 20}}>
              <label>
                Name
              </label>
              <TextField
                placeholder='Enter Name'
                type='text'
                onChange={onChangeName}
                value={name}
                id='customerName'
              />
            </Div>       
          )}
          <label>
            { userId ? `OTP` : `Mobile Number`}
          </label>
          {
            userId ? (
              <TextField
                placeholder='Enter OTP'
                type='number'
                onChange={onChangeOTP}
                value={OTP}
              />
            ): (
              <TextField
                placeholder='10 digit mobile number'
                type='number'
                id='mobileNumber'
                onChange={onChangeNumber}
                value={number}
              />
            )
          }
          {
            userId ? (
              <Div className={classes.alignRight}>
                <Button
                  className={classes.resendBtn}
                  variant='contained'
                  color='primary'
                  onClick={callRecaptcha}
                  disabled={OTPCounter < MAX_OTP_WAIT || fetchingOTP || verifyingOTP}
                >
                  Resend OTP{OTPCounter < MAX_OTP_WAIT ? ` (${MAX_OTP_WAIT - OTPCounter})s` : ''}
                </Button>
              </Div>
            ) : null
          }
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            disabled={!isValid || fetchingOTP || verifyingOTP}
            onClick={userId ? verifyOTP : callRecaptcha}
          >
            {userId ? `Verify OTP` : `Get OTP`}
          </Button>
          <ReCAPTCHA
            sitekey='6Lf7qEMaAAAAAJWV51fKU1KK_pWBIptMws65mna3'
            onChange={fetchOTP}
            size='invisible'
            ref={
              e => {
                if (!recaptchaRef.current) {
                  recaptchaRef.current = e
                }
              }
            }
          />
        </Div>
      </Paper>
    </Modal>
  );
};

export default LoginModal;