import { lazy } from 'react';

// Constants
import constants from 'constants/index';

const DetailPage = lazy(() => import('pages/Detail'));
const BookingPage = lazy(() => import('pages/Booking'));

const routesList = [
  {
    path: constants.DETAIL_URL,
    component: DetailPage,
    exact: true,
  },
  {
    path: constants.BOOKING_URL,
    component: BookingPage,
    exact: true,
  },
];

export default routesList;
