import urls from './urls';

const PAGE_NAME = 'Book your appointment on hate2wait';

const localizedRoutes = {
  detail: 'Consultant Details',
  booking: 'Select your booking slot',
};

const pageTitles = {
  PAGE_NAME,
  [urls.DETAIL_URL]: `${localizedRoutes.detail} - ${PAGE_NAME}`,
  [urls.BOOKING_URL]: `${localizedRoutes.booking} - ${PAGE_NAME}`,
};

export default pageTitles;
