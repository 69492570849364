import { useEffect, useState } from 'react';

// components
import { Button, Modal, Paper, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { actions, selectors } from 'slices';

// styles
import styles from './styles';

import { useAnalyticsEventTracker } from 'services';

const ConfirmModal = () => {
  const history = useHistory();
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const bookingUrl = useSelector(selectors.selectBookingUrl);
  const bookingToken = useSelector(selectors.selectBookingToken);
  const createBookingError = useSelector(selectors.selectCreateBookingError);

  const gaEventTracker = useAnalyticsEventTracker('user_action');

  useEffect(() => {
    if (bookingUrl || createBookingError) {
      setOpen(true);
    }
  }, [bookingUrl, createBookingError]);

  const closeModal = () => {
    setOpen(false);
    gaEventTracker('redirect_none', 'none');
    dispatch(actions.resetBooking());
  }

  const redirectModal = () => {
    setRedirecting(true);
    setOpen(false);
    gaEventTracker('redirect_live_status', 'live_status');
    dispatch(actions.resetBooking());
    window.open(`${bookingUrl}`, '_self');
    //window.location.href = bookingUrl;
  }

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={closeModal}
      style={{ top: '20%' }}
      aria-labelledby='mobile-login'
      aria-describedby='mobile-login-to-create-booking'
    >
      <Paper className={classes.paper}>
        <Div className={classes.heading}>
          {
            createBookingError ? null : (
              'Appointment Confirmed!'
            )
          }
        </Div>
        <Div className={classes.content}>
          {redirecting ? <LinearProgress style={{ top: 2, left: 0, position: 'absolute' }}/> : null}
          {redirecting ? <Div className={classes.info}>Please wait while we take you to the live status page.</Div> : null}
          {
            createBookingError ? (
              <Div>{createBookingError}</Div>
            ) : null
          }
          {
            bookingToken ? (
              <Div>
                <Div className={classes.successfulLabel}>You will receive an SMS with appointment details shortly</Div>
              </Div>
            ) : null
          }
          {
            !redirecting ? (
              !bookingUrl ? (
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={closeModal}
                >
                  Close
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={redirectModal}
                  txt={bookingUrl}
                >
                  Redirect to Live Status Page
                </Button>  
              )
            ) : null
          }
        </Div>
      </Paper>
    </Modal>
  );
};

export default ConfirmModal;