import * as bookingSliceModule from './bookingSlice';
import * as detailSliceModule from './detailSlice';
import * as sessionSliceModule from './sessionSlice';
import * as bookingStatusSliceModule from './bookingStatusSlice';

export const actions = {
  ...bookingSliceModule.actions,
  ...detailSliceModule.actions,
  ...sessionSliceModule.actions,
  ...bookingStatusSliceModule.actions,
};

export const selectors = {
  ...bookingSliceModule.selectors,
  ...detailSliceModule.selectors,
  ...sessionSliceModule.selectors,
  ...bookingStatusSliceModule.selectors,
};

export default {
  booking: bookingSliceModule.default,
  detail: detailSliceModule.default,
  session: sessionSliceModule.default,
  booking_status: bookingStatusSliceModule.default
};