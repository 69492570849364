import { api } from 'services';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import constants from 'constants/index';
import swal from 'sweetalert';

const initialState = {
  userId: null,
  sessionToken: localStorage.getItem(constants.AUTH_TOKEN_KEY),
  fetchingOTP: false,
  fetchOTPError: null,
  mobileNumber: localStorage.getItem(constants.MOBILE_NUMBER_KEY),

  verifyingOTP: false,
  verifyOTPError: null,

  showLoginModal: false,
};

const getSessionToken = ({ session }) => session.sessionToken;
const getUserId = ({ session }) => session.userId;
const getFetchOTPError = ({ session }) => session.fetchOTPError;
const getFetchingOTP= ({ session }) => session.fetchingOTP;
const getVerfiyOTPError = ({ session }) => session.verifyOTPError;
const getVerifyingOTP= ({ session }) => session.verifyingOTP;
const getShowLoginModal = ({ session }) => session.showLoginModal;
const getMobileNumber = ({ session }) => session.mobileNumber;

const selectSessionToken = createSelector(getSessionToken, sessionToken => sessionToken);
const selectUserId = createSelector(getUserId, userId => userId);
const selectFetchOTPError = createSelector(getFetchOTPError, fetchOTPError => fetchOTPError);
const selectFetchingOTP = createSelector(getFetchingOTP, fetchingOTP => fetchingOTP);
const selectVerifyOTPError = createSelector(getVerfiyOTPError, verifyOTPError => verifyOTPError);
const selectVerifyingOTP = createSelector(getVerifyingOTP, verifyingOTP => verifyingOTP);
const selectShowLoginModal = createSelector(getShowLoginModal, showLoginModal => showLoginModal);
const selectMobileNumber = createSelector(getMobileNumber, mobileNumber => mobileNumber);

const fetchOTP = createAsyncThunk(
  'session/fetchOTP',
  async ({ phone_number, customer_name, captcha_token }, thunkAPI) => {
    const data = await api.post('/auth/sign_in', { phone_number, customer_name, captcha_token });
    return { ...data, phone_number };
  }
);

const verifyOTP = createAsyncThunk(
  'session/verifyOTP',
  async ({ user_id, otp_code }, thunkAPI) => {
    const data = await api.post('/auth/verify', { user_id, otp_code: parseInt(otp_code) });
    localStorage.setItem(constants.AUTH_TOKEN_KEY, data?.auth_token);
    localStorage.setItem(constants.MOBILE_NUMBER_KEY, thunkAPI?.getState()?.session?.mobileNumber);
    return data;
  }
);

const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetUserId(state, action) {
      state.userId = null;
    },
    showLoginModal(state, action) {
      state.showLoginModal = true;
    },
    hideLoginModal(state, action) {
      state.showLoginModal = false;
    }
  },
  extraReducers: {
    [fetchOTP.fulfilled]: (state, action) => {
      state.userId = action.payload?.user_id;
      state.mobileNumber = action.payload?.phone_number;
      state.fetchingOTP = false;
    },
    [fetchOTP.pending]: (state, action) => {
      state.fetchingOTP = true
      state.fetchOTPError = null
    },
    [fetchOTP.rejected]: (state, action) => {
      state.fetchOTPError = action.payload;
      state.fetchingOTP = false;
    },
    [verifyOTP.fulfilled]: (state, action) => {
      state.sessionToken = action.payload?.auth_token;
      state.verifyingOTP = false;
      swal({
        text: "Signed In Successfullly",
        icon: "success",
        buttons: false,
        timer: 2000,
      });
    },
    [verifyOTP.pending]: (state, action) => {
      state.verifyOTPError = null;
      state.verifyingOTP = true;
    },
    [verifyOTP.rejected]: (state, action) => {
      state.verifyingOTP = false;
      state.verifyOTPError = action.payload;
    },
  }
});

export const actions = {
  fetchOTP,
  verifyOTP,
  ...session.actions
};

export const selectors = {
  selectSessionToken,
  selectFetchOTPError,
  selectFetchingOTP,
  selectVerifyOTPError,
  selectVerifyingOTP,
  selectUserId,
  selectShowLoginModal,
  getMobileNumber
};

export default session.reducer;