import { combineReducers, configureStore } from '@reduxjs/toolkit';
import slices from 'slices';

const createRootReducer = slices => combineReducers(slices);

const store = configureStore({
  reducer: createRootReducer(slices)
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('slices', () => {
    store.replaceReducer(createRootReducer(require('slices')))
  });
};

export default store