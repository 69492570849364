const styles = ({ breakpoints, palette }) => ({
  root: {
    top: '20%',
    outline: 0,
    borderRadius: 10
  },
  heading: {
    height: 35,
    color: palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.primary.main
  },
  paper: {
    position: 'relative',
    maxWidth: 400,
    minHeight: 164,
    margin: '0 auto',
    '&:focus': {
      outline: 0,
    },
  },
  content: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    textTransform: 'capitalize',
    '&:first-child': {
      marginRight: 20
    },
    '&:second-child': {
      marginLeft: 20
    }
  },
});

export default styles;