import constants from 'constants/index';

const getApiConfig = () => ({
  baseURL: constants.API_BASE_URL,
  timeout: constants.API_TIMEOUT,
});

const apiConfig = getApiConfig();

export default apiConfig;
