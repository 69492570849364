import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import store from 'config/redux';
import theme from 'theme';

const appTheme = createMuiTheme(theme);

const render = () => {
  const App = require('app/App').default;

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  );

}

render();

serviceWorkerRegistration.register();
reportWebVitals();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('app/App', render);
}