//Google analytics
import ReactGA from "react-ga4";

// components
import { AppRouter, LoginModal } from 'components';

// hooks
import { useStyles } from 'hooks';

// styles
import styles from './styles';

import constants from 'constants/index';

const App = () => {
  const classes = useStyles(styles);
  ReactGA.initialize(constants.TRACKING_ID, {gaOptions: { app_version: '1.0.1'}});
  ReactGA.send("pageview");
  return (
    <div className={classes.root}>
      <AppRouter />
      <LoginModal />
    </div>
  );
};

export default App;