import ga from './ga';
import api from './api';
import auth from './auth';
import error from './error';
import pageInfo from './pageInfo';
import strings from './strings';
import urls from './urls';
import whitelabel from './whitelabel';

const constants = {
  ...ga,
  ...api,
  ...auth,
  ...error,
  ...pageInfo,
  ...strings,
  ...urls,
  ...whitelabel
};

export default constants;
