// components
import { Button, Paper, Typography, CircularProgress } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';
import { useState } from 'react';

// styles
import styles from './styles';

const feedbackLink = 'https://forms.gle/mxkskSfZpKZbqnuf6';

const ProfileSummary = ({
  info
}) => {
  const classes = useStyles(styles);
  const [loader, showLoader] = useState(false);

  return (
    <Div className={classes.root}>
      <a href={feedbackLink} className={classes.newfeedbackLink} target="_blank">Give us Feedback!</a>
      <Paper className={classes.avatar} component='span'>
        <img src={info?.image_url} className={classes.image} />
      </Paper>
      <Div className={classes.summary}>
        <Typography className={classes.name}>
          {info?.name}
        </Typography>
        <Typography className={classes.speciality}>
          {info?.speciality}
        </Typography>
        <Typography className={classes.experience}>
          {info?.experience}
        </Typography>
        <Typography className={classes.services}>
          {info?.services}
        </Typography>
        { info?.notice != null ? (
            <><Typography className={classes.noticeTitle}>
              Important Notice:
            </Typography><Typography className={classes.noticeBody}>
                { info?.notice }
            </Typography></>
          ) : ''
        }    
        
{/*
        <a href={feedbackLink} className={classes.feedbackLink} onClick={showLoader}>Give us Feedback!
          <Button color='primary' variant='contained' className={classes.feedbackBtn}>
            {loader ? <CircularProgress className={classes.loader} size={20}/> : null} Feedback
          </Button>
        </a>
*/}
      </Div>
    </Div>
  );
};

export default ProfileSummary;
