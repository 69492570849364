import axiosStandard from 'axios';
import constants from 'constants/index';

// config
import apiConfig from 'config/axios';

const axios = axiosStandard.create(apiConfig);

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  console.log(response)
  if (response?.data?.is_success) {
    return response?.data?.data;
  }
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return Promise.reject(response?.data?.messages || constants.DEFAULT_API_ERROR);
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axios;
