const styles = ({ breakpoints, palette }) => ({
  root: {
    height: 40
  },
  session: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    height: '100%',
    padding: '0 12px',
    justifyContent: 'space-between'
  },
  logout: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  loggedInAs: {
    display: 'flex',
    alignItems: 'center'
  },
  backBtn: {
    paddingRight: 6,
    fontWeight: 600,
    fontSize: 29
  }
});

export default styles;