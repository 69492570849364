import { useEffect, useState } from 'react';

// components
import { Button, Modal, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Div } from 'components';

// hooks
import { useStyles } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';

// redux
import { actions, selectors } from 'slices';

// styles
import styles from './styles';

const ReviewModal = ({
  onClose,
  onAction,
  open,
  title
}) => {
  const classes = useStyles(styles);
  const onYes = () => {
    onAction();
    onClose();
  }

  return (
    <Modal
      open={open}
      className={classes.root}
      onClose={onClose}
      style={{ top: '20%' }}
      aria-labelledby='review-booking'
      aria-describedby='review-booking-before-creating-booking'
    >
      <Paper className={classes.paper}>
        <Div className={classes.heading}>
          Confirmation
        </Div>
        <Div className={classes.content}>
           {title}
        </Div>
        <Div className={classes.footer}>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={onYes}
          >
            Yes
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            onClick={onClose}
          >
            No
          </Button>
        </Div>
      </Paper>
    </Modal>
  );
};

export default ReviewModal;