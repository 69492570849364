import { useEffect, useState } from 'react';

import ReactGA from "react-ga4";

// components
import { AppBar } from '@material-ui/core';
import { Div, ReviewModal } from 'components';
import { ArrowBackIos } from '@material-ui/icons';

//constants
import constants from 'constants/index';

// actions
import { actions, selectors } from 'slices';

// hooks
import { useStyles } from 'hooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// styles
import styles from './styles';

import { useAnalyticsEventTracker } from 'services';

const Header = () => {
  const classes = useStyles(styles);
  const history = useHistory();
  const isBookingPage = useRouteMatch(constants.BOOKING_URL);
  const sessionToken = useSelector(selectors.selectSessionToken);
  const gaEventTracker = useAnalyticsEventTracker('user_action');

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const logout = () => {
    gaEventTracker('sign_out_confirmation_modal', 'sign_out_confirmation_modal');
    setOpenReviewModal(true);
  }
  const onReviewAction = () => {
    gaEventTracker('sign_out', 'sign_out');
    localStorage.removeItem(constants.MOBILE_NUMBER_KEY);
    localStorage.removeItem(constants.AUTH_TOKEN_KEY);
    window.location.href = '/'
  };

  useEffect(() => {
    if (sessionToken) {
      ReactGA.set({ userId: localStorage.getItem(constants.MOBILE_NUMBER_KEY) });
    }
  }, []);

  return (
    <AppBar className={classes.root} position='static'>
      {
        sessionToken ? (
          <Div className={classes.session}>
            <Div className={classes.loggedInAs}>
              {
                isBookingPage ? (
                  <ArrowBackIos className={classes.backBtn} onClick={() => history.push(constants.DETAIL_URL)} />
                ) : null
              }
              Logged in as {localStorage.getItem(constants.MOBILE_NUMBER_KEY)} 
            </Div>
            <Div className={classes.logout} onClick={logout}>
            Logout
            </Div>
          </Div>
        ) : null
      }
      <ReviewModal
        open={openReviewModal}
        onClose={() => setOpenReviewModal(false)}
        onAction={onReviewAction}
        title='Are you sure you want to logout?'
      />
    </AppBar>
  );
};

export default Header;